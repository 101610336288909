
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzModal from "@/components/Modal.vue";
  import { namespace } from "vuex-class";
  import VCalendar from "v-calendar/lib/components/calendar.umd";
  import { apiEvents } from "../api";
  import SearchEvent from "@/components/SearchEvent.vue";
  import _ from "lodash";
  import { CalendarEvents } from "@/events";
  const auth = namespace("auth");

  @Component({ components: { LzButton, VCalendar, LzModal, SearchEvent } })
  export default class Read extends Vue {
    @auth.State("id")
    public ongId!: string;

    getAttributeDescription(attribute: any) {
      return attribute?.customData?.title || "";
    }

    getAttributeColor(attribute: any) {
      return attribute?.customData?.dot;
    }

    getAttributeTitle(attribute: any) {
      return attribute?.customData?.title || "";
    }

    showCalendarDetail(eventId: string) {
      this.$router.push({
        name: "calendarCreate",
        params: { eventId }
      });
    }

    openModal() {
      this.showDayModal = true;
    }
    showSearchModal = false;

    formatDate(date: string) {
      const attributeDate = new Date(date);
      const formattedDate = attributeDate.toLocaleDateString("es-ES", {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric"
      });

      return formattedDate;
    }

    closeModal() {
      this.showDayModal = false;
    }

    setCurrentDay(day: any, attributes: any) {
      this.currentDay.day = day;
      this.currentDay.attributes = attributes;
    }

    resetCurrentDay() {
      this.currentDay.day = null;
      this.currentDay.attributes = null;
    }

    events = [] as any;
    courses = [] as any;

    showDayModal = false;
    currentDay = {
      day: null,
      attributes: null
    };

    get attributes(): any[] {
      return [
        // EVENTS
        ...this.events.map((event: any) =>
          this.getCalendarItem(
            event.id,
            event.title,
            event.salesStartDate,
            "purple",
            event,
            "ArrowBarToRightIcon"
          )
        ),
        ...this.events.map((event: any) =>
          this.getCalendarItem(
            event.id,
            event.title,
            event.salesEndDate,
            "purple",
            event,
            "ArrowBarToLeftIcon"
          )
        ),
        ...this.events.map((event: any) =>
          this.getCalendarItem(
            event.id,
            event.title,
            event.start_time,
            "green",
            event,
            "ArrowBarRightIcon"
          )
        ),
        ...this.events.map((event: any) =>
          this.getCalendarItem(
            event.id,
            event.title,
            event.end_time,
            "green",
            event,
            "ArrowBarLeftIcon"
          )
        )

        // COURSES
        /*
        ...this.courses.map((event: any) =>
          this.getCalendarItem(
            event.id,
            event.title,
            event.salesStartDate,
            "green",
            event,
            "ArrowBarToLeftIcon"
          )
        ),
        ...this.courses.map((event: any) =>
          this.getCalendarItem(
            event.id,
            event.title,
            event.salesEndDate,
            "green",
            event,
            "ArrowBarToRightIcon"
          )
        ),
        ...this.courses.map((event: any) =>
          this.getCalendarItem(
            event.id,
            event.title,
            event.start_time,
            "green",
            event,
            "ArrowBarRightIcon"
          )
        ),
        ...this.courses.map((event: any) =>
          this.getCalendarItem(
            event.id,
            event.title,
            event.end_time,
            "green",
            event,
            "ArrowBarLeftIcon"
          )
        )
        */
      ];
    }

    getCalendarItem(
      key: string,
      title: string,
      date: string,
      color: string,
      item: any,
      icon: string
    ) {
      if (this.getValidDate(date)) {
        return {
          key,
          dates: new Date(this.getValidDate(date)),
          popover: {
            label: title
          },
          customData: {
            ...item,
            dot: color,
            icon
          }
        };
      }
    }

    sortedSearchedAttributes(attributes: CalendarEvents) {
      const uniqueFilteredAttrs = _.uniqBy(attributes, "dates");
      const sortedByDate = _.sortBy(
        uniqueFilteredAttrs,
        event => new Date(event.dates)
      );
      return sortedByDate;
    }

    searchText = "";
    filteredAttributes: any = [];
    handleSearchEvent(searchText: string) {
      this.searchText = searchText;

      if (this.searchText.trim() !== "") {
        const duplicatedFilteredAttrs = this.attributes.filter(
          (attribute: any) =>
            this.getAttributeDescription(attribute)
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
        );

        this.filteredAttributes = this.sortedSearchedAttributes(
          duplicatedFilteredAttrs
        );
      } else {
        this.filteredAttributes = this.sortedSearchedAttributes(
          this.attributes
        );
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getValidDate(date: any): any {
      const d = date.split("-");
      if (d.length > 1) {
        return [d[0], d[1], d[2]].join("/");
      }
    }

    mounted() {
      const today = new Date();
      apiEvents.getEvents(this.ongId).then(({ data }) => {
        this.events = data;
        apiEvents.getCourses(this.ongId).then(({ data }) => {
          data.forEach((item: object) => {
            this.events.push(item);
          });
        });
      });
    }
  }
